

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}


.App {
  text-align: center;
  margin: 0px auto;
  height:100vh;
  max-width:100vw;
  overflow-x:hidden;
}
.right-panel{
  max-width:calc(100vw - 20px);
}
.rtab{
right:0;
}
.rtab.visible{
right:400px;
}
.Block{padding-top:20%; position:absolute; top:0; left:0; background:rgba(255,255,255,.9); width:100%; height:100%; z-index:25;   }
.milestonecon .milestones {
  display: inline-block;
  width: 200px;
}

.milestonecon .wizard {
  margin: 10px;
  display: inline-block;
  width: calc(100% - 250px);
  vertical-align: top;
}
.fullview{ width:calc(100% - 120px); }
.shownav{
  position:relative;
  padding-left: 100px;
}
.fullview.hidenav{ width:100%; }
.hidenav{
  padding:0;
}
.reduced.shownav{ width:calc(100% - 450px); }
.hidenav{
  padding:0;
}
.reduced{
  position:relative;
  width:calc(100% - 400px);
}
.App-header {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}


.contact-form input, select {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

 input,
 select,
 textarea {
  width: 100%; padding:15px;
  font-size:14px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
}

.contact-form button {
  padding: 8px 12px;

  border: none;
  border-radius: 2px;
  cursor: pointer;
}


button:disabled {

  cursor: not-allowed;
}
button{
padding: 10px;
border: none;
border-radius: 2px;

font-size: 16px;
cursor: pointer;
}

.delete {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

/* BotList.css */
.balance-display {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 10px;
  right: 20px;
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  font-size: 16px;
  color: #333;
  width: auto; /* Specify width if needed */
}

.balance-display img {
  width: 42px;
  height: 42px;
  margin-right: 8px;
}


.bot-container {
  margin-top:60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust card width here */
  gap: 20px; /* Spacing between cards */
  padding: 20px;
  background-color: #fff;
}

.bot-card {
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 3px rgba(245, 198, 42, 0.726);
  border-radius: 8px;
}

.deploy-button {
  background-color: #0056b3;
  font-family: "Poetsen One", sans-serif;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.deploy-button:hover {
  background-color: #003d82;
}



.assets .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Styles for the form, scoped to .assets */
/* Main container style, scoped to .assets */
.assets .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f0f2f5; /* Light gray background for the entire container */
  width: 100%; /* Full width */
}

/* Form styling, scoped to .assets */
.assets .form-container {
  width: 90%; /* Width relative to the container */
  max-width: 600px; /* Maximum width for larger screens */
  display: flex;
  justify-content: space-between; /* Distributes space between inputs and button */
  margin-bottom: 20px;
}

.assets .input-field {
  flex-grow: 1; /* Inputs take up available space */
  margin: 0 10px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Rounded borders for inputs */
  font-size: 16px; /* Larger font size for better readability */
}

.assets .submit-button {
  padding: 12px 24px;
  background-color: #007bff; /* Brighter blue for the button */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold; /* Bold text on the button */
  transition: background-color 0.3s;
}

.assets .submit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Table styling, scoped to .assets */
.assets .table {
  width: 90%; /* Table width relative to the container */
  max-width: 800px; /* Maximum width for larger screens */
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.assets th, .assets td {
  border: 1px solid #ddd;
  padding: 15px; /* More padding for table cells */
  text-align: left;
}

.assets th {
  background-color: #007bff; /* Header background to match button color */
  color: white; /* White text for headers */
  font-size: 16px; /* Larger font size for headers */
}

/* Zebra striping for table rows, scoped to .assets */
.assets tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.assets tr:hover {
  background-color: #e9ecef; /* Light hover effect for rows */
}


.middle-row {
  display: flex; /* Establishes a flex container and aligns children in a row */
  align-items: start; /* Aligns items to the top of the container */
  justify-content: space-between; /* Distributes space between items */
  width: 100%; /* Takes full width of the parent container */
  padding: 10px; /* Optional padding for some spacing */
  height: 60vh;
}

.option {
  height:100%;
  max-width:300px;
  flex: 1; /* Gives the option panel some flexibility in taking up space */
  padding: 10px; /* Padding for spacing inside the panel */
  background-color: #f0f0f0; /* Light grey background for visibility */
  margin-right: 10px; /* Space between option panel and chat container */
}

.option-container, .code-container {
  height:100%;
  flex: 3; /* Allows the chat container to take more space */
  display: flex;
  flex-direction: column; /* Stacks the internal chat elements vertically */
  padding: 10px;
  background-color: #fff; /* White background for the chat area */
  border: 1px solid #ccc; /* Border around the chat container */
  margin-right: 10px; /* Space between chat container and code editor */
}
.outer-container {
  width: 30px; /* Define as needed */
  height: 200px; /* Define as needed */
  position: relative; /* Parent positioning context */
  overflow: hidden; /* Ensures no content spills out */
  display: flex; /* Centering child */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.rotated-div:hover { opacity: 0.5; cursor: pointer;}
.rotated-div {
  width: 200px; /* Adjust based on content */
  height: 30px; /* Adjust based on content */
  background-color: rgb(233, 3, 3); /* For visibility */
  box-shadow: 0 -2px 5px #000 inset;
  text-align: center; /* Align text inside the rotated div */
  color:#FFF;
  line-height: 30px; /* Align text vertically */
  transform: rotate(-90deg); /* Rotation angle */
  position: absolute; /* Absolute positioning inside relative container */
}
.chat-container{
  height:100%;  flex: 3; /* Allows the chat container to take more space */
  display: flex;
  flex-direction: column; /* Stacks the internal chat elements vertically */
  padding: 10px;
  background-color: #fff; /* White background for the chat area */
  border: 1px solid #ccc; /* Border around the chat container */
  margin-right: 10px; /* Space between chat container and code editor */
}
.chatbox{
  height:100%;
  flex: 3; /* Allows the chat container to take more space */
  display: flex;
  flex-direction: column; /* Stacks the internal chat elements vertically */
  padding: 10px;
  background-color: #fff; /* White background for the chat area */
  border: 1px solid #ccc; /* Border around the chat container */
  margin-right: 10px; /* Space between chat container and code editor */
}
.option-panel button{ width:100%; }

.code-container {
  height:100%;
  flex: 2; /* Gives the code editor flexibility in taking up space */
  padding: 10px;
  overflow:auto;
  background-color: #e8e8e8; /* Slightly different background for distinction */
}

.flowchart-container {
  width: 100%; /* Takes full width */
  padding: 10px;
  margin-top: 10px; /* Space from the middle row */
  background-color: #f9f9f9; /* Light background for the flowchart container */
  font-weight:100;
  font-family: "Poetsen One", sans-serif;
}

.messages {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: #f1f1f1;
}

.message-info {
  font-size: 0.8em;
  color: #666;
}

.message-text {
  margin-top: 5px;
}
.message-text, pre{ font-family:'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
 }
.message-text.user {
  margin-top: 5px;
  color:blue;
  text-align:right;
}

.message-text.assistant {
  margin-top: 5px;
  text-align:left;
}
.message-text pre{ background: #ccc; padding:4px; max-width:100%; }
.message-text pre:hover{ background: #e0ee1e80; padding:4px; }
.send-message-form {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.message-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.send-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

.flowchart-container {
  display: flex;
/*  flex-wrap: wrap; */
  overflow-x: auto;
  gap: 10px;
  background-color: #f4f4f4; /* Light grey background */
}

.step-block {
  flex: 1 1 200px; /* Each block takes up equal space, minimum 200px */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Soft shadow for 3D effect */
  background-color: #ffffff;
  text-align: center;
}

.step-block.start {
  border-left: 4px solid green; /* Green border for Start steps */
}

.step-block.process {
  border-left: 4px solid blue; /* Blue border for Process steps */
}

.step-block.end {
  border-left: 4px solid red; /* Red border for End steps */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Styles for desktop and larger screens */
@media screen and (min-width: 1200px) {
  /* Add your CSS rules here */
}

/* Styles for tablet-sized screens */
@media screen and (min-width: 440px) and (max-width: 1199px) {
  /* Add your CSS rules here */
  .templatedetails{
    display:none;
  }
 
}

/* Styles for mobile and smaller screens */
@media screen and (max-width: 440px) {
  /* Add your CSS rules here */
  .rtab {
    margin-top: 30vh;
  }
  .rtab.visible{
    left:0;
    right:auto;
    }
    .templatedetails{
      display:none;
    }
  .config >div{
    max-width:100%;
    min-width:0; margin:0;
    display:block;
  }

  
}
